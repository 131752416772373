<template>
  <div class="about-me">
    <h2>Hello World😃! </h2>
    <p class="details intro">
      🙋‍♂️ I am <b>Kaustubh Murumkar</b> , I am a developer 👨🏻‍💻.
    </p>
    <p class="details">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="104" r="32" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></circle><path d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></path></svg>
      Pune, MH, India
    </p>
    <p class="details">
    I like solving Rubik's cubes: 
    </p>
    <div>
    <img width="128px" height="128px" src="@/assets/rubiks_scrambled.svg"/>
    </div>
    <p class="details">
    I also like to tinker with embedded electronics
    </p>
    <div>
    <img width="128px" height="128px" src="@/assets/RaspberryPi_3BPlus.svg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutMe'
}
</script>

<style scoped>
.reader {
  width: 100%;
  padding: 30px 30px;
  border-radius: 0 0 2rem 0;
}

.details {
  margin-top: 30px;
}

.about-me {
  font-size: 18pt;
}

</style>
